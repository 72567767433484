import classNames from 'classnames'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

function PopupPortal({ children }: { children: ReactElement }) {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  return mounted ? createPortal(children, document.querySelector('#popup-portal')!) : null
}

export default function EllipsisMenu({ children }: { children: ReactElement }): JSX.Element {
  const [isOpen, setOpen] = useState<boolean>(false)
  const mainRef = useRef<HTMLDivElement>(null)
  const toggleRef = useRef<HTMLButtonElement>(null)
  const popperRef = useRef<HTMLDivElement>(null)
  const arrowRef = useRef<HTMLDivElement>(null)
  const { styles, attributes, update } = usePopper(toggleRef.current, popperRef.current, {
    placement: 'bottom',
    modifiers: [{ name: 'arrow', options: { element: arrowRef.current } }]
  })

  useEffect(() => {
    if (update) update()
  }, [isOpen, update])

  useEffect(() => {
    // First listener, close for every click except the ones inside popper
    const listener = (e: MouseEvent) => {
      if (popperRef.current && !popperRef.current.contains(e.target as Node)) setOpen(false)
    }

    /* Second listener, close for every click on ellipsis menu's trigger buttons
       (they prevent propagation) using custom events */
    const listener2 = (e: CustomEvent<HTMLElement>) => {
      if (e.detail !== mainRef.current) setOpen(false)
    }

    document.addEventListener('click', listener)
    document.addEventListener('closeDropdown', listener2 as any)

    return () => {
      document.removeEventListener('click', listener)
      document.removeEventListener('closeDropdown', listener2 as any)
    }
  }, [])

  return (
    <div
      onClick={e => {
        e.stopPropagation()
        document.dispatchEvent(new CustomEvent('closeDropdown', { bubbles: true, detail: mainRef.current }))
      }}
      ref={mainRef}
    >
      <button
        className={classNames(
          'transition text-gray-400 hover:text-gray-700 active:text-gray-800',
          isOpen && '!text-gray-800'
        )}
        onClick={() => {
          setOpen(value => !value)
        }}
        ref={toggleRef}
      >
        <i className="fas fa-ellipsis-v"></i>
      </button>

      <PopupPortal>
        <div
          className={classNames('cursor-default z-10', !isOpen && 'invisible')}
          ref={popperRef}
          style={styles.popper}
          onClick={() => {
            setOpen(false)
          }}
          {...attributes.popper}
        >
          {children}
          <div ref={arrowRef} style={styles.arrow} />
        </div>
      </PopupPortal>

      {/* <Popup
        show={isOpen}
        handleClose={() => {
          setOpen(false)
        }}
      >
        {children}
      </Popup> */}
    </div>
  )
}
