import classNames from 'classnames'
import Link from 'next/link'
import { MutableRefObject, useCallback, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { logout } from '../api/auth'
import authAtom from '../atoms/auth'
import { environment } from '../config'
import useFocusTrap from '../hooks/useFocusTrap'
import Card from './Card'
import { Breadcrumbs } from '../containers/Breadcrumbs'

function HeaderDropdown({ handleHide }: { handleHide: () => void }): JSX.Element {
  const setAuthState = useSetRecoilState(authAtom)
  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>
  useFocusTrap(ref, {
    initialFocus: () => ref.current,
    escapeDeactivates: false,
    allowOutsideClick: true
  })

  return (
    <div
      onClick={handleHide}
      onKeyDown={e => {
        if (e.key === 'Escape') handleHide()
      }}
    >
      <Card
        className={classNames('absolute right-0 !p-1 !rounded-lg outline-none bg-white top-7 z-10')}
        innerRef={ref}
        tabIndex={-1}
      >
        {/* <div>Profilo</div>
        <div>Impostazioni</div>
        <hr /> */}
        <Link href="/account" passHref>
          <button className="w-full text-left pr-4 whitespace-nowrap text-gray-700 hover:text-gray-800 hover:bg-gray-100 focus:text-gray-800 focus:bg-gray-100 active:text-gray-900">
            <i className="fas fa-user text-primary w-4 mr-2" />
            Account
          </button>
        </Link>
        <button
          className="w-full text-left pr-4 whitespace-nowrap text-gray-700 hover:text-gray-800 hover:bg-gray-100 focus:text-gray-800 focus:bg-gray-100 active:text-gray-900"
          onClick={() => {
            logout()
            setAuthState({ state: 'loggedOut' })
          }}
        >
          <i className="fas fa-sign-out-alt text-primary w-4 mr-2" />
          Esci
        </button>
      </Card>
      <div className="fixed inset-0"></div>
    </div>
  )
}

export default function Header() {
  const authState = useRecoilValue(authAtom)
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const handleHide = useCallback(() => setShowDropdown(false), [])

  if (authState.state !== 'loggedIn') return null

  return (
    <div className="py-3">
      <div className="w-full flex justify-between">
        <span>
          <Link href="/" passHref>
            <span className="cursor-pointer text-xl">
              <span className="text-gray-500">Agile</span>
              <span className="text-primary font-medium">PersonalBoard</span>
            </span>
          </Link>
          {environment === 'test' && (
            <span className="ml-2 uppercase text-sm text-gray-400 font-medium">(ambiente di test)</span>
          )}
        </span>

        <span className="relative">
          <span
            className={classNames(
              'font-medium cursor-pointer',
              showDropdown ? 'text-gray-600' : 'text-gray-400 hover:text-gray-600'
            )}
            role="button"
            onClick={() => {
              setShowDropdown(true)
            }}
          >
            {authState.user.nome} {authState.user.cognome}
            <i className="fas fa-caret-down ml-1" />
          </span>
          {showDropdown && <HeaderDropdown handleHide={handleHide} />}
        </span>
      </div>
      <Breadcrumbs className="mt-1" />
    </div>
  )
}
