import { Utente } from '@apb/database/schema'
import { capitalize } from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'

export function formatPercentage(value: number): string {
  return `${(value * 100).toFixed(2)} %`
}

export function padNumber(str: string, minLength: number): string {
  if (str.length < minLength) return padNumber(`0${str}`, minLength)
  return str
}

export function average(numbers: number[]): number {
  return numbers.reduce((s, n) => s + n, 0) / numbers.length
}

export function formatDateForInput(date: Date): string {
  return `${date.getFullYear()}-${padNumber((date.getMonth() + 1).toString(), 2)}-${padNumber(
    date.getDate().toString(),
    2
  )}`
}

export const isEmail = (email: string): boolean => Yup.string().email().isValidSync(email)

export function formatFileSize(size: number): string {
  const symbols = ['B', 'kB', 'mB']
  let symbolIndex = 0
  for (; symbolIndex < symbols.length; symbolIndex++) {
    if (size < 1024) break
    size /= 1024
  }
  return `${size.toFixed(2)} ${symbols[symbolIndex]}`
}

export function formatDate(date: Date): string {
  const mom = moment(date)
  if (Date.now() - date.getTime() < 1000 * 60 * 60 * 24) return mom.fromNow()
  return mom.format('LLL')
}

export function formatDuration(ms: number): string {
  const mom = moment.duration(ms)
  return mom.humanize()
}

export function formatRole(role: Utente['role']): string {
  return {
    HR: 'HR',
    DIREZIONE: 'Direzione',
    DIREZIONE_GENERALE: 'Direttore Generale',
    LINE_MANAGER: 'Line Manager',
    MANAGER: 'Manager',
    COLLABORATORE: 'Collaboratore',
    ADMIN: 'Admin'
  }[role]
}
