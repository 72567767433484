import classNames from 'classnames'
import type { DetailedHTMLProps, HTMLAttributes, MutableRefObject, ReactNode } from 'react'

export type CardProps = {
  children: ReactNode
  className?: string
  innerRef?: MutableRefObject<HTMLDivElement>
} & Exclude<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'>
export default function Card({ children, className, innerRef, ...props }: CardProps): JSX.Element {
  return (
    <div
      {...props}
      ref={innerRef}
      className={classNames('rounded-2xl shadow-card px-5 py-3 bg-white min-w-fit', className)}
    >
      {children}
    </div>
  )
}

export type CardHeaderProps = CardProps
export function CardHeader({ children, className }: CardHeaderProps): JSX.Element {
  return <div className={classNames('', className)}>{children}</div>
}

export type PopupCardHeaderProps = CardProps & { handleClose: () => void }
export function PopupCardHeader({ children, className, handleClose }: PopupCardHeaderProps) {
  return (
    <div
      className={classNames(
        'flex !flex-nowrap gap-x-1 items-start justify-between border-b pb-2 -mx-5 px-5',
        className
      )}
    >
      <h3 className="mb-0">{children}</h3>
      <button
        onClick={handleClose}
        className="btn-neutral-icon -mr-2 text-gray-400 hover:text-gray-500 active:text-gray-600"
        tabIndex={-1}
      >
        <i className="fas fa-times" />
      </button>
    </div>
  )
}

export type CardSectionProps = CardProps
export function CardSection({ children, className, ...props }: CardHeaderProps): JSX.Element {
  return (
    <div
      className={classNames(
        '-mx-5 px-5 py-3 border-b last:border-b-0 last:rounded-b-2xl last:pb-3 last:-mb-3',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
