import { Utente } from '@apb/database/schema'
import { useMemo } from 'react'

import { useAuthState } from '../atoms/auth'

export default function useProtected(keys: Utente['role'] | Utente['role'][]): {
  loading: boolean
  authorized: boolean
} {
  const authState = useAuthState()

  return useMemo(
    () => ({
      authorized: !!(
        authState.state === 'loggedIn' && (Array.isArray(keys) ? keys : [keys]).some(k => authState.role === k)
      ),
      loading: authState.state === 'loading'
    }),
    [authState, keys]
  )
}
