import classNames from 'classnames'
import { RefAttributes } from 'react'
import { GroupBase } from 'react-select'
import OriginalSelectCreatable, { CreatableProps } from 'react-select/creatable'
import Select from 'react-select/dist/declarations/src/Select'

import styles from './Select.module.scss'

export default function SelectCreatable<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: CreatableProps<Option, IsMulti, Group> & RefAttributes<Select<Option, IsMulti, Group>> & { error?: boolean }
): JSX.Element {
  const { className, error, ...other } = props

  return (
    <OriginalSelectCreatable
      isClearable
      className={classNames(styles.Select, className)}
      styles={{
        control: (provided, state) => ({
          ...provided,
          background: '#ededed',
          border: 'solid 1px #e5e5e5',

          ...(state.isFocused && { borderColor: '#2196f3 !important', boxShadow: '0px 0px 0px 3px #2196f354' }),
          ...(error && { borderColor: '#ff0000 !important', boxShadow: '0px 0px 0px 3px #ff000040' })
        }),
        valueContainer: provided => ({
          ...provided,
          padding: '0 0.6em',
          whiteSpace: 'nowrap'
        }),
        input: provided => ({
          ...provided,
          margin: '0'
        }),
        indicatorSeparator: provided => ({
          ...provided,
          margin: '0'
        }),
        placeholder: provided => ({
          ...provided,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#9ca3af'
        })
      }}
      {...other}
    />
  )
}
