import { Utente } from '@apb/database/schema'
import { ReactElement } from 'react'

import useProtected from '../hooks/useProtected'
import { useAuthState } from '../atoms/auth'

export type ProtectedViewProps = {
  children: ReactElement | null
  keys: Utente['role'] | Utente['role'][]
  notId?: number | null
}
export default function ProtectedView({ children, keys, notId }: ProtectedViewProps) {
  const authState = useAuthState()
  const { authorized } = useProtected(keys)

  if (authState.state === 'loggedIn' && authState.user.id !== notId && authorized) return children

  return null
}
