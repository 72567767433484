import classNames from 'classnames'
import { useField, useFormikContext } from 'formik'
import { DetailedHTMLProps, InputHTMLAttributes } from 'react'

export default function ErrorField({
  name,
  className,
  containerClassName,
  label,
  type,
  ...props
}: {
  name: string
  type?: string
  label?: string
  className?: string
  containerClassName?: string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>): JSX.Element {
  const [field, meta] = useField(name)
  const { handleSubmit } = useFormikContext()

  return (
    <div className={classNames('mb-3', containerClassName)}>
      <label>
        {label && <span className="font-medium text-gray-700 mb-1 inline-block">{label}</span>}
        <input
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSubmit()
              e.preventDefault()
            }
          }}
          type={type}
          checked={type === 'checkbox' ? field.value : undefined}
          {...field}
          {...props}
          className={classNames(className, meta.error && 'error-input')}
        />
      </label>
      {meta.error && <div className="text-red-500 text-sm font-medium mt-1 -mb-1">{meta.error}</div>}
    </div>
  )
}
