import classNames from 'classnames'
import { Field } from 'formik'

import SelectCreatable from './SelectCreatable'

export default function DipartimentoField({
  name,
  className,
  showLabel,
  dipartimenti,
  isLoading
}: {
  name: string
  className?: string
  showLabel?: boolean
  dipartimenti: string[]
  isLoading: boolean
}): JSX.Element {
  return (
    <Field name={name}>
      {({ field, meta, form }) => (
        <div className={classNames('mb-3', className)}>
          <label>
            {showLabel && <span className="font-medium text-gray-700 mb-1 inline-block">Dipartimento</span>}
            <SelectCreatable
              isClearable
              isLoading={isLoading}
              options={dipartimenti.map(label => ({ label, value: label }))}
              value={
                field.value && {
                  label: field.value,
                  value: field.value
                }
              }
              onChange={(value, event) => {
                if (event.action === 'select-option' || event.action === 'create-option')
                  form.setFieldValue(name, value.value)
                else if (event.action === 'clear') form.setFieldValue(name, null)
              }}
              placeholder="Cerca o crea un dipartimento..."
              error={meta.error}
              formatCreateLabel={newLabel => (
                <>
                  <span className="font-medium">Nuovo dipartimento: </span>
                  {newLabel}
                </>
              )}
              noOptionsMessage={() => 'Crea un dipartimento'}
            />
          </label>
          {meta.error && <div className="text-red-500 text-sm font-medium mt-1 -mb-1">{meta.error}</div>}
        </div>
      )}
    </Field>
  )
}
