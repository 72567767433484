import { useCallback, useRef } from 'react'

export default function useLastValueMemoizedCallback<A extends unknown[], R>(
  callback: (...args: A) => R
): (...args: A) => R {
  const resultRef = useRef<{ lastArgs: string; lastResult: R } | null>(null)

  return useCallback(
    (...args: A) => {
      const stringifiedArgs = JSON.stringify(args)
      if (resultRef.current && stringifiedArgs === resultRef.current.lastArgs) return resultRef.current.lastResult
      const newResult = callback(...args)
      if (!newResult && resultRef.current) return resultRef.current.lastResult
      resultRef.current = { lastArgs: stringifiedArgs, lastResult: newResult }
      return newResult
    },
    [callback]
  )
}
