import Card, { PopupCardHeader } from './Card'

export function DeleteUtentePopup({ onSubmit, handleClose }: { onSubmit: () => void; handleClose: () => void }) {
  return (
    <Card className="w-96" role="dialog" aria-modal="true" tabIndex={-1}>
      <PopupCardHeader className="mb-3" handleClose={handleClose}>
        Elimina l&apos;utente
      </PopupCardHeader>
      {
        <>
          <p>L&apos;utente sarà eliminato permanentemente, assieme a tutti i suoi dati.</p>
          <p className="font-medium mt-2">Sei sicuro?</p>
          <div className="text-center">
            <button
              className="btn-text mr-3"
              onClick={() => {
                handleClose()
              }}
            >
              Annulla
            </button>
            <button
              className="btn-danger"
              onClick={() => {
                onSubmit()
              }}
            >
              Elimina
            </button>
          </div>
        </>
      }
    </Card>
  )
}
