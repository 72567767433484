import { Utente } from '@apb/database/schema'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useField } from 'formik'
import useOpenPopup from 'use-open-popup'

import { useNotifications } from '../atoms/notifications'
import EllipsisMenu from '../containers/EllipsisMenu'
import ProtectedView from '../containers/ProtectedView'
import { formatRole, isEmail } from '../helpers'
import useLastValueMemoizedCallback from '../hooks/useLastValueMemoizedCallback'
import { trpc, trpcClient } from '../trpc'
import Card from './Card'
import SpostaUtentePopup from './SpostaUtentePopup'
import { useCallback } from 'react'
import { DeleteUtentePopup } from './DeleteUtentePopup'
import { EditUtentePopup } from './EditUtentePopup'
import { captureException } from '@sentry/nextjs'

const debounceCheckEmail = AwesomeDebouncePromise(trpcClient.utenti.checkEmail.query, 500)

export function CheckEmail() {
  const memoizedCheckEmail = useLastValueMemoizedCallback(debounceCheckEmail)

  const [, { initialValue }] = useField({
    name: 'email',
    validate: (email: string) => {
      if (email && email !== initialValue && isEmail(email))
        return memoizedCheckEmail({ email }).then(e => {
          if (e?.alreadyUsed) return 'Questa mail è già utilizzata'
        })

      return undefined
    }
  })

  return null
}

type BaseUtente = Pick<
  Utente,
  'id' | 'nome' | 'cognome' | 'email' | 'emailSent' | 'dipartimento' | 'role' | 'aziendaId' | 'parentId' | 'path'
>

export default function UtenteOptions({ mutate, utente }: { mutate: () => void; utente: BaseUtente }): JSX.Element {
  const { createNotification } = useNotifications()

  const { mutateAsync: mutateUpdateUtente } = trpc.utenti.update.useMutation({
    onSettled: mutate,
    onError: (e, { data }) => {
      if (e.message.startsWith('USER_HAS_CHILDREN') && data.role) {
        createNotification({
          title: 'Non consentito',
          description: `Non puoi spostare un utente con collaboratori al ruolo "${formatRole(data.role)}"`,
          style: 'error',
          icon: 'fas fa-times text-red-500'
        })
      } else if (e.message.startsWith('TOO_DIR_GEN')) {
        createNotification({
          style: 'error',
          icon: 'fas fa-times',
          title: 'Spostamento non valido',
          description: "L'azienda non può avere più di un direttore generale"
        })
      }
    }
  })
  const { mutateAsync: mutateDeleteUtente } = trpc.utenti.delete.useMutation({ onSuccess: mutate })

  const openModificaUtente = useOpenPopup(
    useCallback(
      ({ handleClose, utente }: { handleClose: () => void; utente: BaseUtente }) => (
        <EditUtentePopup
          utente={utente}
          refresh={mutate}
          handleClose={handleClose}
          onSubmit={async data => {
            try {
              await mutateUpdateUtente({
                utenteId: utente.id,
                data: {
                  ...data,
                  email: data.email?.length ? data.email : null,
                  password: data.password?.length ? data.password : undefined
                }
              })
            } catch (e) {
              captureException(e)
            }
            handleClose()
          }}
        />
      ),
      [mutate, mutateUpdateUtente]
    )
  )

  const openSpostaUtente = useOpenPopup(
    useCallback(
      ({ handleClose, utente }: { handleClose: () => void; utente: BaseUtente }) => (
        <SpostaUtentePopup
          utente={utente}
          onSubmit={async ({ parentId, role }) => {
            try {
              await mutateUpdateUtente({
                utenteId: utente.id,
                data: { parentId, role }
              })
            } catch (e) {
              captureException(e)
            }
            handleClose()
          }}
          handleClose={handleClose}
        />
      ),
      [mutateUpdateUtente]
    )
  )

  const openDeleteUtente = useOpenPopup(
    useCallback(
      ({ handleClose, utente }: { handleClose: () => void; utente: BaseUtente }) => (
        <DeleteUtentePopup
          handleClose={handleClose}
          onSubmit={async () => {
            await mutateDeleteUtente({
              utenteId: utente.id
            })
            handleClose()
          }}
        />
      ),
      [mutateDeleteUtente]
    )
  )

  return (
    <EllipsisMenu>
      <Card className="!rounded-lg !p-1 flex flex-col">
        <button
          className="w-full transition hover:bg-gray-100 text-gray-500 hover:text-gray-900 text-left"
          onClick={() => {
            openModificaUtente({ utente })
          }}
        >
          <i className="fas fa-edit w-4 mr-2" />
          Modifica
        </button>
        <ProtectedView keys={['ADMIN', 'HR']}>
          <>
            <button
              className="w-full transition hover:bg-gray-100 text-gray-500 hover:text-gray-900 text-left"
              onClick={() => {
                openSpostaUtente({ utente })
              }}
            >
              <i className="fas fa-person-walking-arrow-right w-4 mr-2" />
              Sposta
            </button>
            {utente.role === 'COLLABORATORE' || utente.role === 'MANAGER' ? (
              <button
                className="w-full transition hover:bg-gray-100 text-gray-500 hover:text-gray-900 text-left"
                onClick={async () => {
                  try {
                    await mutateUpdateUtente({
                      utenteId: utente.id,
                      data: { role: utente.role === 'COLLABORATORE' ? 'MANAGER' : 'COLLABORATORE' }
                    })
                    createNotification({
                      title: 'Utente modificato con successo!',
                      style: 'success',
                      icon: 'fas fa-check text-green-500'
                    })
                  } catch (e) {
                    captureException(e)
                  }
                }}
              >
                <i className="fas fa-person-walking-arrow-right w-4 mr-2" />
                Sposta a {utente.role === 'COLLABORATORE' ? 'Manager' : 'Collaboratore'}
              </button>
            ) : null}
          </>
        </ProtectedView>
        <button
          className="w-full transition hover:bg-gray-100 text-gray-500 hover:text-red-600 text-left"
          onClick={() => {
            openDeleteUtente({ utente })
          }}
        >
          <i className="far fa-trash-alt w-4 mr-2" />
          Elimina
        </button>
      </Card>
    </EllipsisMenu>
  )
}
