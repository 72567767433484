import type { AuthState } from '../atoms/auth'
import { clearToken, parseToken, saveToken } from '../services/auth'
import { trpcClient } from '../trpc'

export async function processToken(token: string, remember: boolean): Promise<AuthState> {
  saveToken(token, remember)
  const jwt = parseToken(token)
  if (!jwt) throw new Error('Invalid token')

  const utente = await trpcClient.auth.getInfo.query()

  return { user: jwt, role: utente.role, path: utente.path, state: 'loggedIn' }
}

export type TwoFactorAuthAppData = { uri: string; secret: string }

export const logout = (): void => {
  clearToken()
}
