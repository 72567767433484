import * as focusTrap from 'focus-trap'
import { MutableRefObject, useEffect } from 'react'

export default function useFocusTrap(
  ref: MutableRefObject<HTMLElement> | null | undefined | false,
  focusTrapOptions?: focusTrap.Options
) {
  useEffect(() => {
    if (!ref || !ref.current) return

    const trap = focusTrap.createFocusTrap(ref.current, focusTrapOptions)
    trap.activate()

    return () => {
      trap.deactivate()
    }
  }, [ref, focusTrapOptions])
}
