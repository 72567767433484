import classNames from 'classnames'
import { useMemo, useState } from 'react'

import { trpc } from '../trpc'
import Card, { PopupCardHeader } from './Card'
import Spinner from './Spinner'
import { formatRole } from '../helpers'
import { Utente } from '@apb/database/schema'

const ALL_ROLES = ['DIREZIONE_GENERALE', 'DIREZIONE', 'HR', 'LINE_MANAGER', 'MANAGER', 'COLLABORATORE'] as const
const ROLES_WITHOUT_PARENT = ['DIREZIONE_GENERALE', 'DIREZIONE', 'HR', 'LINE_MANAGER'] as const

export default function SpostaUtentePopup({
  utente,
  onSubmit,
  handleClose
}: {
  onSubmit: (data: Pick<Utente, 'parentId' | 'role'>) => Promise<void>
  utente: Pick<Utente, 'id' | 'aziendaId' | 'parentId' | 'role' | 'path'>
  handleClose: () => void
}): JSX.Element {
  const [selected, setSelected] = useState({ parentId: utente.parentId, role: utente.role })
  const noParent = useMemo(() => ROLES_WITHOUT_PARENT.includes(selected.role as any), [selected.role])

  const { data: azienda } = trpc.aziende.get.useQuery(
    { aziendaId: utente.aziendaId!, withDeepUtenti: true },
    { suspense: false }
  )
  const validUsers = useMemo(
    () =>
      azienda &&
      [...azienda.lineManagers, ...azienda.deepUtenti!.filter(u => u.role === 'MANAGER')].filter(
        u => !u.path.startsWith(utente.path)
      ),
    [azienda, utente]
  )

  const noEdits = useMemo(
    () => selected.parentId === utente.parentId && selected.role === utente.role,
    [selected, utente]
  )

  const validSelected = useMemo(() => noParent || selected.parentId !== null, [noParent, selected])

  return (
    <Card className="flex-shrink w-96" role="dialog" aria-modal="true" tabIndex={0}>
      <PopupCardHeader className="mb-3" handleClose={handleClose}>
        Sposta l&apos;utente
      </PopupCardHeader>
      {!azienda ? (
        <p className="text-center mb-1">
          <Spinner /> Caricando...
        </p>
      ) : azienda.lineManagers.length === 0 ? (
        <p className="text-center mb-1">Non ci sono altri manager associati all&apos;azienda</p>
      ) : (
        <>
          <p className="mb-1 text-justify px-3">Seleziona il ruolo dell&apos;utente:</p>
          <div className="mb-3">
            {ALL_ROLES.map((role, i) => (
              <button
                key={role}
                className={classNames(
                  'group hover:bg-gray-100 active:bg-gray-200 w-full text-left flex border-gray-200 rounded-none',
                  i === 0 && 'rounded-t-lg',
                  i === ALL_ROLES.length - 1 ? 'rounded-b-lg' : 'border-b-2',
                  selected.role === role && 'bg-gray-100'
                )}
                onClick={() => {
                  setSelected(s => ({ ...s, role }))
                }}
              >
                <div>
                  <i
                    className={classNames(
                      'fas fa-arrow-right mr-2 ml-0 group-hover:ml-2 transition-all',
                      selected.role === role
                        ? 'text-primary-dark group-hover:text-primary-darker'
                        : 'text-gray-300 group-hover:text-gray-400'
                    )}
                  />{' '}
                </div>
                <div>
                  <span className="font-medium">{formatRole(role)}</span>
                  {utente.role === role && <span className="text-gray-500 text-xs ml-3">Corrente</span>}
                </div>
              </button>
            ))}
          </div>

          {!noParent &&
            (validUsers ? (
              validUsers.length > 0 ? (
                <>
                  <p className="mb-1 px-3 text-justify">Seleziona un manager a cui trasferire l&apos;utente:</p>
                  <div className="mb-3">
                    {validUsers?.map((m, i) => (
                      <button
                        key={m.id}
                        className={classNames(
                          'group hover:bg-gray-100 active:bg-gray-200 w-full text-left flex border-gray-200 rounded-none !flex-nowrap',
                          i === 0 && 'rounded-t-lg',
                          i === validUsers.length - 1 ? 'rounded-b-lg' : 'border-b-2',
                          selected.parentId === m.id && 'bg-gray-100'
                        )}
                        onClick={() => {
                          setSelected(s => ({ ...s, parentId: m.id }))
                        }}
                      >
                        <div>
                          <i
                            className={classNames(
                              'fas fa-arrow-right mr-2 ml-0 group-hover:ml-2 transition-all',
                              selected.parentId === m.id
                                ? 'text-primary-dark group-hover:text-primary-darker'
                                : 'text-gray-300 group-hover:text-gray-400'
                            )}
                          />
                        </div>
                        <div className="">
                          <p className="font-medium">
                            {m.nome} {m.cognome}
                            {utente.parentId === m.id && (
                              <span className="text-gray-500 text-xs ml-3 whitespace-nowrap">Corrente</span>
                            )}
                          </p>
                          <p className="text-sm text-gray-500">{formatRole(m.role)}</p>
                        </div>
                      </button>
                    ))}
                  </div>
                </>
              ) : (
                <p className="px-3 text-justify mb-3">
                  Non ci sono altri manager a cui è possibile trasferire l&apos;utente.
                </p>
              )
            ) : (
              <p className="text-center mb-3">
                <Spinner /> Caricando...
              </p>
            ))}

          <div className="text-center">
            <button
              className="btn btn-primary"
              disabled={!validSelected || noEdits}
              onClick={() => {
                onSubmit({
                  parentId: noParent ? null : selected.parentId,
                  role: selected.role
                })
              }}
            >
              Sposta
            </button>
          </div>
        </>
      )}
    </Card>
  )
}
