import { Utente } from '@apb/database/schema'
import { Form, Formik, useField, useFormikContext } from 'formik'
import * as Yup from 'yup'
import useDipartimentiOptions from '../hooks/useDipartimentiOptions'
import Card, { PopupCardHeader } from './Card'
import DipartimentoField from './DipartimentoField'
import ErrorField from './ErrorField'
import SendEmailButton from './SendEmailButton'
import Spinner from './Spinner'
import { CheckEmail } from './UtenteOptions'
import ProtectedView from '../containers/ProtectedView'
import { useCallback, useState } from 'react'
import { captureException } from '@sentry/nextjs'
import classNames from 'classnames'
import { useNotifications } from '../atoms/notifications'
import { generate } from 'generate-password-browser'

export type FormData = {
  nome: string
  cognome: string
  email: string
  dipartimento: string | null
  password: undefined | string
}

export function EditUtentePopup({
  utente,
  onSubmit,
  refresh,
  handleClose
}: {
  onSubmit: (data: FormData) => Promise<void>
  refresh: () => void
  handleClose: () => void
  utente: Pick<Utente, 'id' | 'nome' | 'cognome' | 'dipartimento' | 'email' | 'emailSent' | 'aziendaId' | 'role'>
}): JSX.Element {
  const { dipartimenti, isLoading } = useDipartimentiOptions(utente.aziendaId)

  return (
    <Card className="flex-shrink w-96" role="dialog" aria-modal="true" tabIndex={-1}>
      <PopupCardHeader className="mb-3" handleClose={handleClose}>
        Modifica l&apos;utente
      </PopupCardHeader>
      <Formik<FormData>
        onSubmit={onSubmit}
        initialValues={{
          nome: utente.nome,
          cognome: utente.cognome,
          email: utente.email || '',
          password: '',
          dipartimento: utente.dipartimento ?? null
        }}
        validationSchema={Yup.object().shape({
          nome: Yup.string().min(2, 'Troppo corto').required('È richiesto'),
          cognome: Yup.string().min(2, 'Troppo corto').required('È richiesto'),
          email: Yup.string().email("L'email non é valida").nullable(),
          dipartimento: Yup.string().min(2, 'Troppo corto').nullable()
        })}
      >
        {({ isSubmitting }) => (
          <Form>
            <ErrorField name="nome" label="Nome" className="w-full" type="text" placeholder="Nome..." />
            <ErrorField name="cognome" label="Cognome" className="w-full" type="text" placeholder="Cognome..." />
            <ErrorField name="email" label="Email" className="w-full" type="text" placeholder="Email..." />
            {utente.email && (
              <SendEmailButton utenteId={utente.id} emailSent={utente.emailSent} refresh={refresh} className="-mt-1" />
            )}
            <CheckEmail />
            {utente.role !== 'ADMIN' && (
              <DipartimentoField name="dipartimento" showLabel dipartimenti={dipartimenti} isLoading={isLoading} />
            )}
            <ProtectedView keys={['ADMIN', 'HR']}>
              <NewPasswordField />
            </ProtectedView>
            <div className="text-center">
              <button type="submit" className="btn-success">
                {isSubmitting && <Spinner className="mr-2" />}
                Salva
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  )

  function NewPasswordField() {
    const [{ value }] = useField<FormData['password']>('password')
    const { setFieldValue } = useFormikContext<FormData>()
    const [visible, setVisible] = useState(false)

    const toggle = useCallback(() => {
      setVisible(v => !v)
    }, [])

    const { createNotification } = useNotifications()

    const copyPassword = useCallback(async () => {
      try {
        await navigator.clipboard.writeText(value || '')
      } catch (e) {
        captureException(e)

        createNotification({
          title: 'Errore',
          description: 'Impossibile copiare la password negli appunti',
          timeout: 3000,
          style: 'error',
          icon: 'fas fa-times'
        })
      }

      createNotification({
        title: 'Copiata',
        description: 'La nuova password è stata copiata negli appunti',
        timeout: 3000,
        style: 'success',
        icon: 'fas fa-clipboard'
      })
    }, [value, createNotification])

    const generateRandom = useCallback(() => {
      const newPassword = generate({
        length: 12,
        numbers: true,
        uppercase: true,
        excludeSimilarCharacters: true
      })
      setFieldValue('password', newPassword)
      setVisible(true)
      copyPassword()
    }, [setFieldValue, copyPassword])

    return (
      <div className="relative">
        <ErrorField
          name="password"
          label="Nuova Password"
          className="w-full"
          type={visible ? 'text' : 'password'}
          placeholder="Nuova password..."
          autoComplete="new-password"
        />
        <div className="absolute right-0 top-0">
          <button
            title="Mostra/nascondi password"
            onClick={toggle}
            className={classNames(
              'btn-icon text-sm py-1 px-2 text-gray-400 hover:text-gray-500 active:text-gray-600',
              visible && 'text-primary hover:text-primary-dark active:text-primary-darker'
            )}
            type="button"
          >
            <i className="fas fa-eye" />
          </button>
          <button
            title="Copia password negli appunti"
            onClick={copyPassword}
            type="button"
            className={classNames(
              'btn-icon text-sm py-1 px-2 text-gray-400 hover:text-gray-500 active:text-primary-dark',
              ''
            )}
          >
            <i className="fas fa-clipboard" />
          </button>
          <button
            title="Genera una password sicura"
            onClick={generateRandom}
            type="button"
            className={classNames(
              'btn-icon text-sm py-1 px-2 text-gray-400 hover:text-gray-500 active:text-primary-dark'
            )}
          >
            <i className="fas fa-refresh" />
          </button>
        </div>
        {value && value.length > 0 && (
          <div className="text-sm text-gray-700 -mt-1 mb-2">
            <i className="fas fa-warning text-yellow-500" /> <span className="font-medium">Attenzione!</span> La
            password corrente dell&apos;utente verrà sovrascritta.
          </div>
        )}
      </div>
    )
  }
}
