import { useMemo } from 'react'

import { trpc } from '../trpc'

export default function useDipartimentiOptions(aziendaId?: number | null, dipartimentiSelezionati?: string[]) {
  const emptyInputs = aziendaId === undefined || aziendaId === null

  const { data: dipartimentiEsistenti, error } = trpc.aziende.getDipartimenti.useQuery(
    { aziendaId: aziendaId as number },
    { enabled: !emptyInputs, suspense: false }
  )

  const dipartimenti = useMemo(
    () => [...(dipartimentiEsistenti ?? []), ...(dipartimentiSelezionati ?? [])],
    [dipartimentiEsistenti, dipartimentiSelezionati]
  )

  const isLoading = emptyInputs && !error

  return {
    isLoading,
    dipartimenti,
    error
  }
}
