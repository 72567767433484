import classNames from 'classnames'
import { trpc } from '../trpc'

export default function SendEmailButton({
  emailSent,
  utenteId,
  refresh,
  className
}: {
  emailSent: boolean
  utenteId: number
  refresh: () => void
  className?: string
}): JSX.Element {
  const { mutate: mutateSendEmail, status } = trpc.utenti.sendEmail.useMutation({
    onSuccess: () => {
      refresh()
    }
  })

  return (
    <div className={classNames('whitespace-nowrap self-end mb-3 h-9 flex items-center', className)}>
      {status === 'loading' ? (
        <span className="text-gray-500">Inviando mail...</span>
      ) : status === 'error' ? (
        <span className="text-danger font-medium">Errore!</span>
      ) : emailSent || status === 'success' ? (
        <span className="text-gray-500">
          Mail di accesso inviata!
          <button
            className="btn-link btn-link-primary underline"
            onClick={() => {
              mutateSendEmail({ utenteId })
            }}
          >
            Reinvia mail di accesso
          </button>
        </span>
      ) : (
        <button
          className="btn-link-primary text-sm"
          onClick={() => {
            mutateSendEmail({ utenteId })
          }}
        >
          Invia mail di primo accesso
        </button>
      )}
    </div>
  )
}
